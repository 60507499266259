'use client'

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { getSiteMode } from '@wt/utilities/siteMode';

export default function FAQ() {
  const faqs =
    getSiteMode() == 'whentaken'
      ? [
          {
            question: 'When is a new game available?',
            answer: (
              <p className="text-sm md:text-base">
                A new game is available every day at 0:00 am, local time of your
                device!
              </p>
            ),
          },
          {
            question: 'Can I play past games?',
            answer: (
              <p className="text-sm md:text-base">
                Yes, you can head to the Archive section from the menu and play
                all of the WhenTaken archive games starting from the 28th of
                February 2024.
              </p>
            ),
          },
          {
            question: 'What are the scoring rules?',
            answer: (
              <div className="flex flex-col gap-3 text-sm md:text-base">
                <div>
                  <span className="font-bold">Scoring system</span>
                  <ul className="mt-2 flex flex-col gap-1 md:ml-2">
                    <p>• 5 rounds will get you a maximum of 1000 points.</p>
                    <p>
                      • Each round 200 points: 100 max for Location and 100 max
                      for Year.
                    </p>
                  </ul>
                </div>
                <div>
                  <span className="font-bold">
                    Scoring for guessing the year
                  </span>
                  <ul className="mt-2 flex flex-col gap-1 md:ml-2">
                    <p>
                      • For guesses within 5 years, the deduction is 1 point per
                      year.
                    </p>
                    <p>
                      • For guesses 6 to 10 years away, the deduction is 2
                      points per year after the first 5 years.
                    </p>
                    <p>
                      • For guesses 11 to 20 years away, the deduction is 3
                      points per year after the first 10 years.
                    </p>
                    <p>
                      • For guesses more than 20 years away, the deduction is 4
                      points per year after the first 20 years.
                    </p>
                  </ul>
                </div>
                <p className="italic">
                  Example: The correct year is 1990. The user guesses 1974. The
                  year difference between the guessed year (1974) and the
                  correct year (1990) is 16 years. For the first 5 years, the
                  deduction is 1 point per year, totalling 5 points deducted.
                  For years 6 to 10, the deduction is 2 points per year,
                  totalling an additional 10 points deducted (2 points per year
                  for 5 years). For years 11 to 16, the deduction is 3 points
                  per year, totalling an additional 18 points deducted (3 points
                  per year for 6 years). Therefore, the total deduction is 5 +
                  10 + 18 = 33. The total score: 100 - 33 = 67 points.
                </p>
                <div>
                  <span className="font-bold">
                    Scoring for guessing the location
                  </span>
                  <ul className="mt-2 flex flex-col gap-1 md:ml-2">
                    <p>
                      • The points will be awarded based on distance from the
                      correct answer.
                    </p>
                    <p>
                      • For each distance bracket, the value of 1 km will be
                      different, meaning that the farther the guess is, the
                      fewer maximum points the player can get.
                    </p>
                    <div>
                      • Brackets:
                      <div className="ml-4 mt-2 flex flex-col gap-1">
                        <p>
                          • <span className="font-semibold">0-100</span> km: min{' '}
                          <span className="font-semibold">95 points</span>, max{' '}
                          <span className="font-semibold">100 points</span>,
                          decrease by 0.05 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">101-500</span> km:
                          min <span className="font-semibold">85 points</span>,
                          max <span className="font-semibold">95 points</span>,
                          decrease by 0.025 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">501-1000</span> km:
                          min <span className="font-semibold">70 points</span>,
                          max <span className="font-semibold">85 points</span>,
                          decrease by 0.03 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">1001-2000</span> km:
                          min <span className="font-semibold">50 points</span>,
                          max <span className="font-semibold">70 points</span>,
                          decrease by 0.02 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">2001 - 5000</span>{' '}
                          km: min{' '}
                          <span className="font-semibold">20 points</span>, max{' '}
                          <span className="font-semibold">50 points</span>,
                          decrease by 0.01 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">5001 - 10000</span>{' '}
                          km: min{' '}
                          <span className="font-semibold">0 points</span>, max{' '}
                          <span className="font-semibold">20 points</span>,
                          decrease by 0.004 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">≥ 10001</span> km:{' '}
                          <span className="font-semibold">0 points</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      • For the guessed distance within a bracket, calculate the
                      points by subtracting the appropriate decrement per
                      kilometre from the max points of that bracket.
                    </p>
                    <p>
                      • <span className="font-semibold">Final formula</span>:
                      Location Points=Max Points for Bracket-((Distance in
                      km-Bracket Starting Distance) &times; Point Decrement per
                      km) + 10 points for Correct Country if (Distance&gt;500
                      km)
                    </p>
                  </ul>
                </div>
                <p className="italic">
                  Example: The correct location is Kyiv, and the player guesses
                  Odesa. The location points are calculated this way: the
                  distance between Kyiv and Odesa is 475 km, which falls into
                  the 101-500 km bracket, so the max number of points the user
                  can get is 95. Calculations: Location Points = 95 - ((475 -
                  101) &times; 0.025) = 90 - (374 &times; 0.025) = 95 - 9.35 =
                  85.65 points. Rounding this to the nearest whole number, it
                  would be 86 points.
                </p>
              </div>
            ),
          },
          {
            question: 'Why is your location incorrect?',
            answer: (
              <div className="flex flex-col gap-2 text-sm md:text-base">
                <p>
                  We're doing our best to collect the most accurate data to
                  provide you with the best gaming experience - we even use
                  Street View to find the most accurate coordinates, however,
                  sometimes we make mistakes or can't find the exact location,
                  so bear with us. Another reason why the location might be
                  slightly off is that we were unable to find the exact location
                  and instead used the city coordinates.
                </p>
                <p>
                  If you find a mistake, please, report it and suggest another
                  set of coordinates through the form by clicking on the ⚠️
                  button. We really appreciate the input from locals who help us
                  make our data more precise.
                </p>
              </div>
            ),
          },
          {
            question: 'What games contribute to my stats?',
            answer: (
              <p className="text-sm md:text-base">
                Only the daily game contributes to your stats. Community games
                and Archive games will not affect your stats.
              </p>
            ),
          },
        ]
      : [
          {
            question: 'When is a new game available?',
            answer: (
              <p className="text-sm md:text-base">
                A new game is available every day at 0:00 am, local time of your
                device!
              </p>
            ),
          },
          {
            question: 'Can I play past games?',
            answer: (
              <p className="text-sm md:text-base">
                Yes, you can head to the Archive section from the menu and play
                all of the WhereTaken archive games starting from the 15th of
                July 2024.
              </p>
            ),
          },
          {
            question: 'What are the scoring rules?',
            answer: (
              <div className="flex flex-col gap-3 text-sm md:text-base">
                <div>
                  <span className="font-bold">Scoring system</span>
                  <ul className="mt-2 flex flex-col gap-1 md:ml-2">
                    <p>• 5 rounds will get you a maximum of 1000 points.</p>
                  </ul>
                </div>
                <div>
                  <span className="font-bold">
                    Scoring for guessing the location
                  </span>
                  <ul className="mt-2 flex flex-col gap-1 md:ml-2">
                    <p>
                      • The points will be awarded based on distance from the
                      correct answer.
                    </p>
                    <p>
                      • For each distance bracket, the value of 1 km will be
                      different, meaning that the farther the guess is, the
                      fewer maximum points the player can get.
                    </p>
                    <div>
                      • Brackets:
                      <div className="ml-4 mt-2 flex flex-col gap-1">
                        <p>
                          • <span className="font-semibold">0-100</span> km: min{' '}
                          <span className="font-semibold">190 points</span>, max{' '}
                          <span className="font-semibold">200 points</span>,
                          decrease by 0.1 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">101-500</span> km:
                          min <span className="font-semibold">170 points</span>,
                          max <span className="font-semibold">190 points</span>,
                          decrease by 0.05 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">501-1000</span> km:
                          min <span className="font-semibold">140 points</span>,
                          max <span className="font-semibold">170 points</span>,
                          decrease by 0.06 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">1001-2000</span> km:
                          min <span className="font-semibold">100 points</span>,
                          max <span className="font-semibold">140 points</span>,
                          decrease by 0.04 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">2001 - 5000</span>{' '}
                          km: min{' '}
                          <span className="font-semibold">40 points</span>, max{' '}
                          <span className="font-semibold">100 points</span>,
                          decrease by 0.02 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">5001 - 10000</span>{' '}
                          km: min{' '}
                          <span className="font-semibold">0 points</span>, max{' '}
                          <span className="font-semibold">40 points</span>,
                          decrease by 0.008 points per km
                        </p>
                        <p>
                          • <span className="font-semibold">≥ 10001</span> km:{' '}
                          <span className="font-semibold">0 points</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      • For the guessed distance within a bracket, calculate the
                      points by subtracting the appropriate decrement per
                      kilometre from the max points of that bracket.
                    </p>
                    <p>
                      • <span className="font-semibold">Final formula</span>:
                      Location Points = Max Points for Bracket - ((Distance in
                      km - Bracket Starting Distance) &times; Point Decrement
                      per km) + 10 points for Correct Country if
                      (Distance&gt;500 km)
                    </p>
                  </ul>
                </div>
                <p className="italic">
                  Example: The correct location is Kyiv, and the player guesses
                  Odesa. The location points are calculated this way: the
                  distance between Kyiv and Odesa is 475 km, which falls into
                  the 101-500 km bracket, so the max number of points the user
                  can get is 95. Calculations: Location Points = 95 - ((475 -
                  101) &times; 0.025) = 90 - (374 &times; 0.025) = 95 - 9.35 =
                  85.65 points. Rounding this to the nearest whole number, it
                  would be 86 points.
                </p>
              </div>
            ),
          },
          {
            question: 'Why is your location incorrect?',
            answer: (
              <div className="flex flex-col gap-2 text-sm md:text-base">
                <p>
                  We're doing our best to collect the most accurate data to
                  provide you with the best gaming experience - we even use
                  Street View to find the most accurate coordinates, however,
                  sometimes we make mistakes or can't find the exact location,
                  so bear with us. Another reason why the location might be
                  slightly off is that we were unable to find the exact location
                  and instead used the city coordinates.
                </p>
                <p>
                  If you find a mistake, please, report it and suggest another
                  set of coordinates through the form by clicking on the ⚠️
                  button. We really appreciate the input from locals who help us
                  make our data more precise.
                </p>
              </div>
            ),
          },
          {
            question: 'What games contribute to my stats?',
            answer: (
              <p className="text-sm md:text-base">
                Only the daily game contributes to your stats. Community games
                and Archive games will not affect your stats.
              </p>
            ),
          },
        ];

  return (
    <div id="faq" className="flex w-full flex-col items-center justify-center">
      <span className="mb-4 text-2xl font-semibold md:mb-6 md:text-5xl">
        FAQ
      </span>
      {faqs.map((faq, i) => {
        return (
          <Accordion
            key={i}
            className="m-0 flex w-full flex-col items-center border-b-2 border-secondary bg-transparent py-4 text-inherit md:py-6"
            sx={{
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
              backgroundImage: 'none',
              '&.MuiPaper-root': {
                margin: 0,
              },
              '& .MuiAccordionDetails-root': {
                margin: 0,
              },
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
              '& .MuiCollapse-root': {
                minWidth: '100%',
              },
            }}
          >
            <AccordionSummary
              className="flex w-full flex-row justify-between text-base font-semibold text-inherit hover:cursor-pointer md:text-2xl md:font-medium"
              expandIcon={<ExpandMoreRoundedIcon className="text-base-300" />}
            >
              {faq.question}
            </AccordionSummary>
            <AccordionDetails>{faq.answer}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}